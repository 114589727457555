<template lang="pug">
    div
        filters
            slot(slot="filters")
                span Name Search - 
                span Date Range Search
            slot(slot="buttons")
                regular-button(:value="BUTTONS_VALUES.add_event" @click="$router.push('/add')")
        events
</template>

<script>
//Components
import Filters from '@/components/common/filters';
import Events from '@/components/views/events';
import RegularButton from '@/components/common/buttons/regular-button';
//Store
import { EventsNameSpace, EventsActionTypes } from '@/store/events/types';
//Config
import { BUTTONS_VALUES } from '@/configs/names.js';

export default {
    name: "EventsListPage",
    components: {
        'filters': Filters,
        'events': Events,
        'regular-button': RegularButton,
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        }
    },
    mounted() {
        if (this.$route.params?.id) {
            this.onGetEvents({ params: { parent: this.$route.params.id } })    
        } else {
            this.onGetEvents({ params: { parent: '%A0' } })
        }
    },
    methods: {
        async onGetEvents({ params }) {
            await this.$store.dispatch(`${EventsNameSpace}/${EventsActionTypes.GetEvents}`, {params})
        }
    }
}
</script>
<template lang="pug">
    app-table-tr
        app-table-td
            p +
        app-table-td
            p {{ item.name }}
        app-table-td
            p {{  moment(item.date_start).format('dd, DD MMMM YY') }}
        app-table-td
            p {{ moment(item.date_end).format('dd, DD MMMM YY') }}
        app-table-td
            p Подробнее
        app-table-td
            template(v-for="user in item.participants")
                .w-5.h-5.border.rounded-full(:style="`background-image: url(${base_url}/${user.photo.path}); background-size: cover;`")
            .text-xs.text-left Всего {{item.participants.length}}
        app-table-td
            switcher-style-one.m-auto(
                :status="+item.status ? true : false"
                :title="{on: SWITCHERS_VALUES.active, off: SWITCHERS_VALUES.blocked}"
            )
        app-table-td
            p {{ item.type }}
        app-table-td
            p {{ item.parent?.information?.title ? item.parent?.information?.title : '---' }}
        app-table-td
            subevents-informer.cursor-pointer(
                @click="$router.push(`list/${item._id}`)"
                :parent="item._id"
            )
        app-table-td
            img.block.m-auto(:src="ICONS.pencil")
        app-table-td
            inline-svg.m-auto(
                :src="ICONS.bin" 
                @click="() => { if (deleteFilter) {onDeleteConfirmation(item)} }"
                :fill-opacity="deleteFilter ? 1 : 0.25"
            )
</template>

<script>
//Components
import DefaultTableTr from '@/components/common/tables/default-tr';
import DefaultTableTd from '@/components/common/tables/default-td';
import SwitcherStyleOne from '@/components/common/switchers/style-1';
import SubeventsInformer from './subevents-informer';
//Store
import { EventsNameSpace, EventsActionTypes, EventsMutationTypes } from '@/store/events/types';
import { RootGetterTypes } from '@/store/types';
//Configs
import { SWITCHERS_VALUES } from '@/configs/names.js';
import { ICONS } from '@/configs/icons';
//Mixins
import { deleteConfirmation, closeModalDialog } from '@/mixins/modals';

export default {
    name: "BaseEvent",
    mixins: [deleteConfirmation, closeModalDialog],
    components: {
        'app-table-tr': DefaultTableTr,
        'app-table-td': DefaultTableTd,
        'switcher-style-one': SwitcherStyleOne,
        'subevents-informer': SubeventsInformer,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            delete_event_id: null,
        }
    },
    computed: {
        SWITCHERS_VALUES() {
            return SWITCHERS_VALUES
        },
        ICONS() {
            return ICONS
        },
        base_url() {
            return process.env.VUE_APP_BASE_URL
        },
        deleteFilter() {
            return this.$store.getters[`${RootGetterTypes.GetDeleteFilterStatus}`]
        },
    },
    methods: {
        dateFormat({ date }) {
            return this.$moment(date).format('dddd Do YYYY')
        },        
        async onDeleteConfirmation({_id}) {
            this.delete_event_id = _id
            this.deleteConfirmation({ confirm: 'onDeleteEvent' })
            
        },
        async onDeleteEvent() {
            await this.$store.dispatch(`${EventsNameSpace}/${EventsActionTypes.DeleteEvent}`, { id: this.delete_event_id }) 
            this.closeModalDialog()
            this.delete_event_id = null
        }
    },
    beforeDestroy() {
        this.$store.commit(`${EventsNameSpace}/${EventsMutationTypes.SetEmptyEvents}`)
    }
}
</script>s
<template lang="pug">
    div
        app-table
            slot(slot="header")
                table-header(:list="EVENTS_LIST_TABLE_HEADERS")
            slot(slot="tbody")
                template(v-for="(item, index) in events")
                    base-event(
                        :key="item._id" 
                        :index="index+1" 
                        :item="item"
                    )                    
</template>

<script>
//Components
import DefaultTable from '@/components/common/tables/default-table';
import TableHeader from '@/components/common/tables/table-header';
import BaseEvent from './components/base-event';
//Store
import { EventsNameSpace, EventsGetterTypes, EventsMutationTypes } from '@/store/events/types';
//Configs
import { EVENTS_LIST_TABLE_HEADERS } from '@/configs/names.js';

export default {
    name: "Events",
    components: {
        'app-table': DefaultTable,
        'table-header': TableHeader,
        'base-event': BaseEvent,
    },
    data() {
        return {
            delete_event_id: null,
        }
    },
    computed: {
        EVENTS_LIST_TABLE_HEADERS() {
            return EVENTS_LIST_TABLE_HEADERS
        },
        events() {
            return this.$store.getters[`${EventsNameSpace}/${EventsGetterTypes.GetEvents}`]
        },
    },
    beforeDestroy() {
        this.$store.commit(`${EventsNameSpace}/${EventsMutationTypes.SetEmptyEvents}`)
    }
}
</script>s
<template lang="pug">
    div
        template(v-if="children.length")
            button.w-50.h-30.bg-gray-100.border-1.rounded(@click="$emit('click')") {{ children.length }}
        template(v-else)
            p нет
</template>

<script>
//Store
import { EventsNameSpace, EventsActionTypes } from '@/store/events/types';

export default {
    name: "SubeventsInformer",
    props: {
        parent: {
            type: String,
        }
    },
    data() {
        return {
            children: [],
        }
    },
    methods: {
        async onGetEvents({ params = null, readonly = false }) {
            const {data} = await this.$store.dispatch(`${EventsNameSpace}/${EventsActionTypes.GetEvents}`, { params, readonly })
            return data.data
        },
        async onGetEventChildren(parent) {
            const data = await this.onGetEvents({ params: { "parent": parent }, readonly: true })
            this.children = data
        }
    },
    watch: {
        parent: {
            handler(val) {
                this.onGetEventChildren(val)
            },
            immediate: true,
        }
    }
}
</script>